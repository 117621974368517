import makeStyles from '@mui/styles/makeStyles';

export const transitionProps = {
	exit: {
		length: 0.195,
	},
	entry: {
		delay: 0.195 / 2,
		length: 0.225,
	},
}

export const usePageTransitionStyles = makeStyles(
	theme => ({
		transition: {
			transition: theme.transitions.create('opacity', {
				duration: theme.transitions.duration.short,
			}),
		},
		exited: {
			opacity: 0,
		},
		exiting: {
			opacity: 0,
		},
		entering: {
			opacity: 1,
		},
		entered: {
			opacity: 1,
		},
	}),
	{ name: 'transition' }
)
