import React from 'react'

import { ThemeProvider, StyledEngineProvider, getContrastRatio, useTheme } from '@mui/material/styles';
import { Box } from '@mui/material'

import { lightTheme, darkTheme } from '../themes'

const getColor = (theme, color) => {
	if (!theme.palette[color]) {
		// Return a transparent color compatible with getContrastRatio()
		return 'rgba(0, 0, 0, 0.0)'
	}

	return theme.palette[color].main
}

const useBackgroundContrast = (theme, color) => {
	if (!color) {
		return { color: theme.palette.background.default, contrastTheme: theme }
	}

	const contrastTheme =
		theme.palette.mode === 'light' ? darkTheme : lightTheme
	const contrastRatio = getContrastRatio(
		theme.palette.text.primary,
		getColor(theme, color)
	)
	const newTheme = contrastRatio > 7 ? theme : contrastTheme

	return { color: getColor(theme, color), contrastTheme: newTheme }
}

const Block = ({ children, color }) => {
	const theme = useTheme()
	const { backgroundColor, contrastTheme } = useBackgroundContrast(
		theme,
		color
	)

	const style = color ? { backgroundColor } : null

	return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={contrastTheme}>
                <section style={style}>
                    <Box py={8}>{children}</Box>
                </section>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default Block
