import React from 'react'
import PropTypes from 'prop-types'
import { Link as GatsbyLink } from 'gatsby'

import { styled, useTheme } from '@mui/material/styles'
import { AppBar, Box, Container, Toolbar } from '@mui/material'

import { ResponsiveNavList } from '../components/nav'
import logoLight from '../images/supersupplier-logo-light.png'
import logoDark from '../images/supersupplier-logo-dark.png'

const MaxWidthImg = styled('img')({ width: '100%' })

const createTransition = props => theme =>
	theme.transitions.create(props, {
		duration: theme.transitions.duration.shorter,
	})

const Header = ({ isLandingPage, position }) => {
	const theme = useTheme()

	return (
		<AppBar
			color={'transparent'}
			elevation={0}
			position={position}
			sx={{
				backgroundColor: theme =>
					isLandingPage ? theme.palette.background.landingPage : null,
				padding: theme => ({
					xs: theme.spacing(1, 0),
					sm: theme.spacing(2, 0),
				}),
				transition: createTransition('padding'),
			}}
		>
			<Toolbar>
				<Container
					sx={{
						alignItems: 'center',
						display: 'flex',
						height: theme => theme.spacing(6),
						justifyContent: 'space-between',
						transition: createTransition('padding'),
					}}
				>
					<Box
						component={GatsbyLink}
						to={'/'}
						sx={{
							alignItems: 'center',
							display: 'flex',
							textDecoration: 'none',
							mr: 2,
							transition: createTransition('width'),
							width: theme => ({
								xs: theme.spacing(20),
								sm: theme.spacing(22),
								md: theme.spacing(24),
							}),
						}}
					>
						<MaxWidthImg
							src={
								theme.palette.mode === 'dark'
									? logoLight
									: logoDark
							}
							alt={'supersupplier logo'}
							width={192}
						/>
					</Box>
					<ResponsiveNavList />
				</Container>
			</Toolbar>
		</AppBar>
	)
}

Header.propTypes = {
	isLandingPage: PropTypes.bool,
}

export default Header
