const parseCompanyData = companyData => {
	return {
		name: companyData['name'],
		advertText: companyData['adverttext'],
		contact: {
			tel: companyData['telephone'],
			fax: companyData['fax'],
		},
		descr: companyData['companydescr'],
		systemCode: companyData['systemcode'],
		productsAndServices: companyData['productsandservices'],
	}
}

export { parseCompanyData }
