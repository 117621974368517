import React from 'react'

import Block from '../components/Block'
import Header from '../components/Header'
import Footer from '../components/Footer'

const DefaultLayout = ({ children }) => {
	return (
		<>
			<Header position={'absolute'} />
			{children}
			{/* <Block color={'charcoal'}>
				<Footer />
			</Block> */}
		</>
	)
}

export { DefaultLayout }
