import React from 'react'
import cx from 'classnames'

import makeStyles from '@mui/styles/makeStyles';
import { ListSubheader } from '@mui/material'

const useStyles = makeStyles(
	theme => ({
		navHeader: {
			color: theme.palette.text.primary,
			fontSize: '1rem',
		},
	}),
	{ name: 'VerticalNavHeader' }
)

const VerticalNavHeader = ({ children, className, ...rest }) => {
	const classes = useStyles()
	return (
		<ListSubheader
			className={cx(classes.navHeader, className)}
			disableSticky
			{...rest}
		>
			{children}
		</ListSubheader>
	)
}

export default VerticalNavHeader
