import React from 'react'

import { ListItem, ListItemText } from '@mui/material'
import { Skeleton } from '@mui/material';

const ListItemSkeleton = () => {
	return (
		<ListItem>
			<ListItemText
				primary={<Skeleton variant={'text'} width={360} />}
				secondary={<Skeleton variant={'text'} width={200} />}
			/>
		</ListItem>
	)
}

export { ListItemSkeleton }
