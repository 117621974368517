import React from 'react'

import { Box, Skeleton } from '@mui/material'

const SupplierPageSkeleton = () => {
	return (
		<>
			<Box mb={2}>
				<Skeleton
					variant={'text'}
					height={47}
					sx={{ width: { xs: '100%', sm: 400 } }}
				/>
			</Box>
			<Box display={'flex'}>
				<Skeleton
					variant={'rectangular'}
					height={38}
					width={112}
					sx={{ borderRadius: 38, mr: 1 }}
				/>
				<Skeleton
					variant={'rectangular'}
					height={38}
					width={105}
					sx={{ borderRadius: 38 }}
				/>
			</Box>
			<Box my={2}>
				<Skeleton variant={'text'} height={28} width={180} />
				<Skeleton variant={'text'} height={28} width={180} />
			</Box>
			<Box py={1.25}>
				<Skeleton variant={'text'} height={16} width={47} />
			</Box>
			<Box>
				<Skeleton
					variant={'text'}
					height={28}
					sx={{ width: { xs: '100%', sm: 560, md: 640 } }}
				/>
				<Skeleton
					variant={'text'}
					height={28}
					sx={{ width: { xs: '100%', sm: 560, md: 640 } }}
				/>
				<Skeleton
					variant={'text'}
					height={28}
					sx={{ width: { xs: '100%', sm: 560, md: 640 } }}
				/>
				<Skeleton variant={'text'} height={28} width={150} />
			</Box>
		</>
	)
}

export { SupplierPageSkeleton }
