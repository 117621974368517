import axios from 'axios'

import { parseCompanyData } from './parsers'

const apiInstance = axios.create({
	baseURL: process.env.GATSBY_API_URL,
	auth: {
		username: process.env.GATSBY_API_AUTH_USERNAME,
		password: process.env.GATSBY_API_AUTH_PASSWORD,
	},
})

const getBranches = (custaccountTinyMCE, config) =>
	apiInstance
		.post('/getBranches', { custaccountTinyMCE }, config)
		.then(response => response.data)

const getCompany = (custaccountTinyMCE, config) =>
	apiInstance
		.post('/getCompany', { custaccountTinyMCE }, config)
		.then(response => parseCompanyData(response.data))

const quickSearch = ({ page = 1, pageSize = 10, query }, config) =>
	apiInstance
		.post(
			'/quickSearch',
			{
				phrase: query,
				resultPage: page,
				resultsPerPage: pageSize,
			},
			config
		)
		.then(response => response.data)

const productSearch = ({ page = 1, pageSize = 10, query }, config) =>
	apiInstance
		.post(
			'/productSearch',
			{
				phrase: query,
				resultPage: page,
				resultsPerPage: pageSize,
			},
			config
		)
		.then(response => response.data)

const fullSearch = ({ page = 1, pageSize = 10, query }, config) =>
	apiInstance
		.post(
			'/fullSearch',
			{
				phrase: query,
				resultPage: page,
				resultsPerPage: pageSize,
			},
			config
		)
		.then(response => response.data)

export { getBranches, getCompany, quickSearch, productSearch, fullSearch }
